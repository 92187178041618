import React from 'react';
import { graphql } from 'gatsby'
import ImageGallery from 'react-image-gallery';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { List, Row, Col } from 'antd';

// import '../styles/gallery_portee.scss'
// import "react-image-gallery/styles/css/image-gallery.css";

const Portee = ({ data }) => {
    const { slug, mere, pere, mere_img, pere_img, date, photo_principale, males, femelles, naissances } = data.markdownRemark.frontmatter

    const title = slug.replace(/([0-9])/g, "")
    // {title.replace(/(-)/g," ")}

    const races = [
        {
            name: "shetland",
            type: "chiots"
        },
        {
            name: "chihuahua",
            type: "chiots"
        },
        {
            name: "epagneul",
            type: "chiots"
        },
        {
            name: "whippet",
            type: "chiots"
        },
        {
            name: "british",
            type: "chatons"
        }]

    let raceSelected = null
    races.forEach(race => {
        if (slug.toLowerCase().includes(race.name)) {
            raceSelected = race
        }
    })

    const onErrorSrc = (event) => {
        event.target.src = data.logo.childImageSharp.fluid.src;
        event.target.parentNode.className = "blog-list_post_img";
        event.target.style.width = "200px";
        event.target.style.margin = "10px 0";
    }


    return (
        <Layout page={raceSelected.type} titles={[raceSelected.type, raceSelected.name.toUpperCase()]}>
            <SEO title={title.replace(/(-)/g, " ")} keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]} />
            <div className="portees">
                <Row type="flex" justify="space-between" align="middle" gutter={24} style={{ textAlign: "center", margin: "auto" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3>{pere} et {mere}</h3>
                        <p style={{ width: "100%", textAlign: 'center' }}>Naissance le {date}</p>
                        <Row type="flex" justify="space-between" align="middle" gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {males > 0 &&
                                    <div className="portees_chiots portees_chiots_males" style={{ textAlign: "center", margin: "auto" }}>
                                        {males} {males > 1 ? "mâles disponibles" : "mâle disponible"}
                                    </div>}
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {femelles > 0 &&
                                    <div className="portees_chiots portees_chiots_femelles" style={{ textAlign: "center", margin: "auto" }}>
                                        {femelles} {femelles > 1 ? "femelles disponibles" : "femelle disponible"}
                                    </div>}
                            </Col>
                        </Row>
                        <div style={{ maxWidth: "500px", textAlign: "center", margin: "25px auto" }}>
                            <img src={photo_principale} alt={slug} style={{ height: "auto", width: "100%" }} onError={event => onErrorSrc(event)} />
                        </div>
                    </Col>
                </Row>
                {naissances.map((naissance, index) => {
                    let array_photos = []
                    return (
                        <List.Item key={index}>
                            <Row type="flex" justify="space-between" align="top" gutter={24} style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <div style={{ width: "100%", maxWidth: "500px" }}>
                                        {naissance.photos && naissance.photos.length > 0 && naissance.photos.map((photo, index) => {
                                            let obj_photo = {};
                                            obj_photo.original = photo
                                            obj_photo.thumbnail = photo
                                            array_photos[index] = obj_photo
                                            return array_photos;
                                        }) &&
                                            <ImageGallery items={array_photos} showPlayButton={false} showFullscreenButton={false} lazyLoad={true} />
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <h3>{naissance.name}</h3>
                                    {naissance.sexe === "male" && naissance.disponible === 0 &&
                                        <div className="portees_chiots portees_chiots_males reserve" style={{ textAlign: "center", margin: "auto" }}>
                                            mâle reservé
                                        </div>}
                                    {naissance.sexe === "male" && naissance.disponible === 1 &&
                                        <div className="portees_chiots portees_chiots_males" style={{ textAlign: "center", margin: "auto" }}>
                                            mâle disponible {naissance.price ? '-' + naissance.price : ''}
                                        </div>}
                                    {naissance.sexe === "femelle" && naissance.disponible === 0 &&
                                        <div className="portees_chiots portees_chiots_femelles reserve" style={{ textAlign: "center", margin: "auto" }}>
                                            femelle reservée
                                        </div>}
                                    {naissance.sexe === "femelle" && naissance.disponible === 1 &&
                                        <div className="portees_chiots portees_chiots_femelles" style={{ textAlign: "center", margin: "auto" }}>
                                            femelle disponible {naissance.price ? '-' + naissance.price : ''}
                                        </div>}
                                    {naissance.disponible === 1 &&
                                        <div style={{ textAlign: "center", margin: "auto", paddingTop: 15 }}>
                                            <a target="_blank" href={encodeURI(`https://docs.google.com/forms/d/e/1FAIpQLSf04qroOHJGPCSOvhnqdzvrjXbLTuGWTU4OKPV1RUBhgTRPgQ/viewform?entry.708148703=${raceSelected.type === 'chiots' ? 'Chien' : 'Chat'}&amp;entry.2022707792=${raceSelected.name}&amp;entry.1996685485=${date}&amp;entry.1986303311=${naissance.sexe == 'male' ? 'Mâle' : 'Femelle'}&amp;entry.1511584599=Le+remboursement+de+la+somme+versée`)}>
                                                <button name="bondecommande" class="Form--SubmitButton">Créer bon de commande</button>
                                            </a>
                                        </div>
                                    }

                                </Col>
                            </Row>
                        </List.Item>)
                })}
                <Row type="flex" justify="space-between" align="middle" gutter={24} style={{ textAlign: "center", margin: "auto" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3>Les parents</h3>
                        <Row type="flex" justify="space-between" align="middle" gutter={24}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div style={{ maxWidth: "500px", textAlign: "center", margin: "25px auto" }}>
                                    <img src={pere_img} alt={pere} style={{ height: "auto", width: "100%" }} />
                                    <p style={{ textAlign: 'center' }}>{pere}</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <div style={{ maxWidth: "500px", textAlign: "center", margin: "25px auto" }}>
                                    <img src={mere_img} alt={mere} style={{ height: "auto", width: "100%" }} />
                                    <p style={{ textAlign: 'center' }}>{mere}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
}

export default Portee;

export const query = graphql`
    query($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug } }){
            frontmatter {
                slug
                mere
                mere_img
                pere
                pere_img
                date
                photo_principale
                males
                femelles
                naissances { 
                    name
                    sexe
                    price
                    disponible
                    photos
                }
            }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
        }
    }
`